<template>
  <table class="tbl_search">
    <colgroup>
      <col width="50px"><col width="152px"><col width="152px">
    </colgroup>
    <tbody>
    <tr>
      <th>주소 입력</th>
    </tr>
    <tr>
      <td colspan="1">
        <input type="text" id="" name="" v-model="z">
      </td>
      <td>
        <!-- 아래 코드 삽입 및 customCallback 구현 -->
        <e-search-address @setAddr="customCallback" />
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <input type="text" id="" name="" v-model="a">
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import ESearchAddress from '@/components/common/ESearchAddress'

export default ({
  components: {
    ESearchAddress
  },

  data () {
    return {
      z: '00011',
      a: '서울 종로'
    }
  },

  methods: {
    customCallback (addrInfo) {
      console.log(addrInfo)
      this.z = addrInfo.zipNo
      this.a = addrInfo.roadFullAddr
    }
  }
})
</script>
